import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iframe iframeManufactoryTasks pa-3"},[_c('SocketWatch',{attrs:{"tables":['manufactory']},on:{"loadData":_vm.loadData}}),_c(VCard,[_c(VCardTitle,[_vm._v(" Задания в работе "),_c(VSpacer)],1),_c(VDivider),(!_vm.tasksLoaded && (!_vm.tasks || _vm.tasks.length === 0))?_c(VSkeletonLoader,{staticClass:"pl-3 pr-3",attrs:{"type":"table-thead, table-row-divider@3","types":_vm.skeletonTypesIframe}}):_vm._e(),(_vm.tasksLoaded && (!_vm.tasks || _vm.tasks.length === 0))?_c('div',{staticClass:"pa-3 d-flex justify-center"},[_vm._v(" Нет заданий ")]):_vm._e(),(_vm.tasks && _vm.tasks.length > 0)?_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Дата")]),_c('th',{staticClass:"text-left"},[_vm._v("Клиент")]),_c('th',{staticClass:"text-left"},[_vm._v("Комментарий")]),_c('th',{staticClass:"text-left"},[_vm._v("Менеджер")])])]),_c('tbody',_vm._l((_vm.tasks),function(task){return _c('tr',{key:task.id,staticClass:"tableItemList",on:{"click":function($event){return _vm.editTask(task)}}},[_c('td',{staticStyle:{"min-width":"80px"}},[_vm._v(_vm._s(_vm.$formatDate(new Date(task.date))))]),_c('td',{staticStyle:{"min-width":"125px"}},[_vm._v(_vm._s(task.client.name))]),_c('td',[_vm._v(_vm._s(task.comment))]),_c('td',[_vm._v(_vm._s(task.manager.displayName))])])}),0)]},proxy:true}],null,false,473640786)}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }